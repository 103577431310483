var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("gov-heading", { attrs: { size: "m" } }, [_vm._v("Search")]),
      _c(
        "form",
        {
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.$emit("search")
            }
          }
        },
        [
          _vm._t("default"),
          _vm.showAll ? [_vm._t("extra-filters")] : _vm._e(),
          !_vm.hideExtra
            ? _c(
                "gov-form-group",
                [
                  !_vm.showAll
                    ? _c(
                        "gov-link",
                        {
                          on: {
                            click: function($event) {
                              _vm.showAll = true
                            }
                          }
                        },
                        [_vm._v("Show extra filters")]
                      )
                    : _c(
                        "gov-link",
                        {
                          on: {
                            click: function($event) {
                              _vm.showAll = false
                            }
                          }
                        },
                        [_vm._v("Hide extra filters")]
                      )
                ],
                1
              )
            : _vm._e(),
          _c(
            "gov-form-group",
            [
              _c("gov-button", { attrs: { type: "submit" } }, [
                _vm._v("Search")
              ])
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }