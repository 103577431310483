var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "gov-list",
    { attrs: { bullet: _vm.bullet } },
    _vm._l(_vm.nodes, function(node) {
      var _obj
      return _c(
        "li",
        { key: node.id },
        [
          _vm._t("label", [_vm._v(_vm._s(node.label))], { node: node }),
          _vm._v(" \n    "),
          _c(
            "span",
            [
              _vm._t(
                "edit",
                [
                  _vm.canEdit
                    ? _c(
                        "gov-link",
                        {
                          attrs: {
                            to: {
                              name: _vm.edit,
                              params:
                                ((_obj = {}),
                                (_obj[_vm.nodeType] = node.id),
                                _obj)
                            }
                          }
                        },
                        [_vm._v("\n          Edit\n        ")]
                      )
                    : _vm._e()
                ],
                { node: node }
              ),
              _vm._v(" \n      "),
              _vm._t(
                "moveUp",
                [
                  node.order > 0
                    ? _c(
                        "gov-link",
                        {
                          on: {
                            click: function($event) {
                              return _vm.$emit("move-up", node)
                            }
                          }
                        },
                        [_vm._v("(Move up)")]
                      )
                    : _vm._e()
                ],
                { node: node }
              ),
              _vm._v(" \n      "),
              _vm._t(
                "moveDown",
                [
                  node.order < _vm.nodes.length - 1
                    ? _c(
                        "gov-link",
                        {
                          on: {
                            click: function($event) {
                              return _vm.$emit("move-down", node)
                            }
                          }
                        },
                        [_vm._v("(Move down)")]
                      )
                    : _vm._e()
                ],
                { node: node }
              ),
              _vm._v(" \n      "),
              _vm._t("status", null, { node: node })
            ],
            2
          ),
          _vm._t("default", [
            node.children
              ? _c("ck-tree-list", {
                  key: "node." + node.id + ".children",
                  attrs: {
                    nodes: node.children,
                    edit: _vm.edit,
                    nodeType: _vm.nodeType,
                    bullet: _vm.bullet
                  },
                  on: {
                    "move-up": function($event) {
                      return _vm.$emit("move-up", $event)
                    },
                    "move-down": function($event) {
                      return _vm.$emit("move-down", $event)
                    }
                  },
                  scopedSlots: _vm._u(
                    [
                      _vm._l(_vm.$scopedSlots, function(_, name) {
                        return {
                          key: name,
                          fn: function(statusProps) {
                            return [
                              _vm._t(name, null, { node: statusProps.node })
                            ]
                          }
                        }
                      })
                    ],
                    null,
                    true
                  )
                })
              : _vm._e()
          ])
        ],
        2
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }